import Vue from 'vue'
import Component from 'vue-class-component';
import timeformat from '@/filters/timeformat'
import runtimeProcessorModule from '@/store/modules/runtime-processor.module';
import { timerActiveModule } from '@/store/modules/timer-active.module';
import { ctrlCmdModule } from '@/store/modules/control-commands.module';
import { notificationModule } from '@/store/modules/notification.module';
import { NotificationCommand, NotificationType, UserAnswer } from '@/types/notification';
import { PAGES } from '@/types/router';
import timerActiveManager from '@/store/managers/timer-active.manager';

@Component({
    filters: { timeformat },
    beforeRouteEnter (to, from, next) {
        if (timerActiveModule.timerWorkout != null) {
            Vue.gtm.trackEvent({
                event: 'svt-timer-start',
                timerId: timerActiveModule.timerWorkout.id,
                timerConfig: timerActiveModule.timerWorkout
            })
            next()
        }
    }
})
export default class TimerActiveComponent extends Vue {

    private runtimeProcessWatcher!: CallableFunction
    private notificationAnswerWatcher!: CallableFunction
    private timeFrameWatcher!: CallableFunction
    private timerTimeWatcher!: CallableFunction
    private controlCommandsWatcher!: CallableFunction

    get totalTimeInMinutes(): number {
        return timerActiveModule.totalTime / 60
    }    

    get time() {
        if (timerActiveModule.isItStopWatch && !timerActiveModule.isItPreparationTime) {
            return runtimeProcessorModule.elapsedTimeSeconds
        }
        return ((timerActiveModule.totalTime as number) -
          (timerActiveModule.elapsedTime as number) -
          runtimeProcessorModule.elapsedTimeSeconds)
          * 1000 // to MS
    }
    
    get isPaused() {
        return runtimeProcessorModule.paused
    }

    mounted() {

        this.notificationAnswerWatcher = notificationModule.$watch(
            thisModule => thisModule.answer,
            (answer: UserAnswer) => {
                this.userAnswerHandler(answer)
            })

        this.runtimeProcessWatcher = runtimeProcessorModule.$watch(timerModule => timerModule.status, status => {

            const { stopped, finished } = status

            if (finished) { // finished automatically

                if (!timerActiveModule.isItTheLastTimeFrame) {
                    timerActiveModule.loadTheNextTimeFrame()
                    timerActiveModule.timeFrameStart()
                } else {
                    this.$router.push({ name: PAGES.TIMERS })
                }
            } else if (stopped && this.$route.name == PAGES.TIMERS_ACTIVE) {
                this.$router.push({ name: PAGES.TIMERS })
            }
        })

        this.timeFrameWatcher = timerActiveModule.$watch(m => m.timeFrameCoordinates, () => {
            timerActiveManager.timeFrameSwitchingProcessor(timerActiveModule.activeTimeFrame)
        })

        this.timerTimeWatcher = runtimeProcessorModule.$watch(timerModule => timerModule.currentTime,
            (currentTime, prevTimeMarker) => {
                timerActiveManager.syncOverTime({ currentTime, prevTimeMarker })
            })

        this.controlCommandsWatcher = ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback,
            lastMessage => {
                if (lastMessage != undefined) {
                    timerActiveManager.controlCommandsProcessor(lastMessage)
                }
            })
        timerActiveManager.start()
    }

    pause() {
        timerActiveManager.pause()
    }

    stop() {
        notificationModule.showNotification({ command: NotificationCommand.ARE_YOU_SURE, type: NotificationType.CONFIRM })
    }

    resume() {
        const { paused } = runtimeProcessorModule.status
        if (paused) {
            timerActiveManager.resume()
        }
    }

    userAnswerHandler(answer: UserAnswer) {
        if (answer === UserAnswer.POSITIVE) {
            timerActiveManager.stop()
        }
    }

    beforeDestroy() {

        if (this.timeFrameWatcher) {
            this.timeFrameWatcher()
        }

        if (this.timerTimeWatcher) {
            this.timerTimeWatcher()
        }

        if (this.runtimeProcessWatcher) {
            this.runtimeProcessWatcher()
        }

        if (this.controlCommandsWatcher) {
            this.controlCommandsWatcher()
        }

        if (this.notificationAnswerWatcher) {
            this.notificationAnswerWatcher()
        }
        timerActiveManager.destroy()
    }
}