import { TIMER_TYPE } from '@/types/timer'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'

@Module
class RuntimeProcessorModule extends VuexModule {

    public currentTime = 0 // ms
    public serviceInterval = 0
    public timerType: TIMER_TYPE = TIMER_TYPE.DOWN
    public step = 160 // ms
    public totalTime = 0 // ms

    public paused = true
    public stopped = false

    get status(): {stopped: boolean; started: boolean; paused: boolean; finished: boolean } {

        const finished = (this.currentTime == 0 && this.timerType == TIMER_TYPE.DOWN) && !this.stopped && !this.paused

        const stopped = this.stopped
        const started = this.started
        const paused = this.paused

        return  { stopped, started, paused, finished }
    }

    get started(): boolean {
        return this.currentTime > 0
    }

    get timerStep(): number {
        return this.step
    }

    get isItInProcess(): boolean {
        return this.started
    }

    get elapsedTimeSeconds(): number {

        if (this.timerType == TIMER_TYPE.DOWN && this.totalTime > this.currentTime) {
            return (this.totalTime - this.currentTime) / 1000
        } else if (this.timerType == TIMER_TYPE.UP) {
            return this.currentTime
        }
        return 0
    }

    @Mutation
    private toDefault()
    {
        this.currentTime = 0 // ms
        this.serviceInterval = 0
        this.timerType = TIMER_TYPE.DOWN
        this.step = 160 // ms
        this.totalTime = 0 // ms

        this.paused = true
        this.stopped = false
    }

    @Mutation
    private changeTime (time: number) {

        if (this.timerType == TIMER_TYPE.DOWN) {
            this.currentTime -= time
        } else if (this.timerType == TIMER_TYPE.UP) {
            this.currentTime += time
        }

        if (this.currentTime < 0) {
            this.currentTime = 0
        }
    }

    @Mutation
    private setTime (time: number) {
        this.currentTime = time
    }

    @Mutation
    private setTotalTime (time: number) {
        this.totalTime = time
    }

    @Mutation
    private onStart() {
        this.paused = false
        this.stopped = false
    }

    @Mutation
    private onPause() {
        this.paused = true
        this.stopped = false
    }

    @Mutation
    private onStop() {
        this.paused = false
        this.stopped = true
    }

    @Mutation
    private initServiceInterval(serviceInterval: number) {
        this.serviceInterval = serviceInterval
    }

    @Mutation
    private clearServiceInterval() {
        clearInterval(this.serviceInterval)
    }
    

    @Mutation
    private setTimerType(timerType: TIMER_TYPE) {
        this.timerType = timerType
    }

    @Action
    changeTimerType(timerType: TIMER_TYPE) {
        this.setTimerType(timerType)
    }

    @Action
    currentTimeReset(time: number) {
        this.setTime(time)
    }

    @Action
    totalTimeReset(time: number) {
        this.setTotalTime(time)
    }

    @Action
    tick() {

        if (this.timerType == TIMER_TYPE.DOWN && this.currentTime < this.timerStep ) {
            this.setTime(0)
        } else if (this.timerType == TIMER_TYPE.UP && this.totalTime > 0 && this.totalTime - this.currentTime < this.timerStep) {
            this.setTime(this.totalTime)
        } else {
            this.changeTime(this.timerStep)
        }
    }

    @Action // eslint-disable-next-line
    start(totalTime = 0) {

        totalTime *= 1000
        this.setTotalTime(totalTime)
        this.setTime(totalTime)
        this.startTimer()
        this.onStart()
    }

    @Action
    stop() {
        this.clearServiceInterval()
        this.currentTimeReset(0)
        this.totalTimeReset(0)
        this.onStop()
    }

    @Action
    pause() {
        this.clearServiceInterval()
        this.onPause()
    }

    @Action
    resume() {
        this.onStart()
        this.startTimer()
    }

    @Action
    startTimer() {
        this.clearServiceInterval()
        this.initServiceInterval(window.setInterval(() => this.tick(), this.timerStep))
    }

    @Action
    reset()
    {
        this.clearServiceInterval()
        this.toDefault()
    }
}

export default new RuntimeProcessorModule({ store, name: 'runtimeProcessorModule' })