import Vue from 'vue'
import Component from 'vue-class-component'
import WorkoutActiveTimerComponent from '@/components/workout-active-timer/workout-active-timer.component.vue'
import runtimeProcessorModule from '@/store/modules/runtime-processor.module'
import timeformat from '@/filters/timeformat'
import { ctrlCmdModule } from '@/store/modules/control-commands.module'
import { CTRL_COMMAND_FEEDBACK } from '@/types/socket'
import monthlyTrainingActiveWorkoutModule from '@/store/modules/monthly-training-workout-active'
import { notificationModule } from '@/store/modules/notification.module'
import { UserAnswer, NotificationCommand, NotificationType } from '@/types/notification'
import { TRAINING_TRIGGERED_BY } from '@/types/active-training'

@Component({
    components: {
        'timer': WorkoutActiveTimerComponent
    },
    filters: { timeformat }
})
export default class MonthlyTrainingActivePageComponent extends Vue {

    private notificationAnswerWatcher!: CallableFunction

    runtimeProcessWatcher!: CallableFunction
    onWorkoutStopWatcher!: CallableFunction

    isItPaused = true

    get timeLeftMs() {
        return monthlyTrainingActiveWorkoutModule.currentTimeProgressSec * 1000
    }

    get isItScheduled() {
        return monthlyTrainingActiveWorkoutModule.triggeredBy === TRAINING_TRIGGERED_BY.SCHEDULE
    }

    mounted() {

        this.notificationAnswerWatcher = notificationModule.$watch(
            thisModule => thisModule.answer,
            (answer: UserAnswer) => {
                this.userAnswerHandler(answer)
            })

        this.runtimeProcessWatcher = runtimeProcessorModule.$watch(timerModule => timerModule.status, status => {
            
            const { paused, finished, stopped } = status

            if (stopped || (finished && monthlyTrainingActiveWorkoutModule.isItTheLastTimeFrame)) {
                this.$router.back()
            } else {
                this.isItPaused = paused
            }
        })

        this.start()
    }

    forward() {
        if (monthlyTrainingActiveWorkoutModule.isItTheLastRoundNumber) {
            this.stop()
        } else {
            monthlyTrainingActiveWorkoutModule.forward()
        }
    }

    backward() {
        monthlyTrainingActiveWorkoutModule.backward()
    }

    pause() {
        monthlyTrainingActiveWorkoutModule.pause()
    }

    resume() {
        if (runtimeProcessorModule.status.paused) {
            monthlyTrainingActiveWorkoutModule.resume()
        }
    }

    start() {

        this.onWorkoutStopWatcher = ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, feedback => {

            if (feedback?.event == CTRL_COMMAND_FEEDBACK.WORKOUT_STOPPED) {
                monthlyTrainingActiveWorkoutModule.stop()
                ctrlCmdModule.cmdDisposal(feedback.event)
            }
        })
        monthlyTrainingActiveWorkoutModule.start()
    }

    stop() {
        if (this.isItScheduled) {
            notificationModule.showNotification({ command: NotificationCommand.CANNOT_STOP_TRAINING, type: NotificationType.ALERT })
        } else {
            notificationModule.showNotification({ command: NotificationCommand.ARE_YOU_SURE, type: NotificationType.CONFIRM })
        }
    }

    userAnswerHandler(answer: UserAnswer) {
        if (answer === UserAnswer.POSITIVE && !this.isItScheduled) {
            this.onWorkoutStopWatcher()
            monthlyTrainingActiveWorkoutModule.stop()
        }
    }

    beforeDestroy() {
        monthlyTrainingActiveWorkoutModule.resetToDefault()
        this.notificationAnswerWatcher()
        this.onWorkoutStopWatcher()
        this.runtimeProcessWatcher()
    }
}