import Vue from 'vue'
import Component from 'vue-class-component'
import WorkoutActiveTimerComponent from '@/components/workout-active-timer/workout-active-timer.component.vue'
import WorkoutActiveLoaderComponent from "@/components/workout-active-loader/workout-active-loader.component.vue"
import runtimeProcessorModule from '@/store/modules/runtime-processor.module'
import timeformat from '@/filters/timeformat'
import { TIMER_TYPE } from '@/types/timer'
import workoutActiveModule from '@/store/modules/workout-active.module'
import { notificationModule } from '@/store/modules/notification.module'
import { UserAnswer, NotificationCommand, NotificationType } from '@/types/notification'
import { TRAINING_TRIGGERED_BY } from '@/types/active-training'
import { WorkoutTypes } from '@/types/workouts'
import { ctrlCmdModule } from '@/store/modules/control-commands.module'
import { CTRL_COMMAND_FEEDBACK } from '@/types/socket'
import workoutActiveManager from '@/store/managers/workout-active.manager'
import { eGymTrainingsModule } from '@/store/modules/egym-trainings.module'
import { PAGES } from '@/types/router'
import { appModule } from '@/store/modules/app.module'

@Component({
    components: {
        'timer': WorkoutActiveTimerComponent,
        'loader': WorkoutActiveLoaderComponent
    },
    filters: { timeformat },
    beforeRouteLeave: (to, from, next) => {
        next()
    }
})
export default class WorkoutActivePageComponent extends Vue {

    private notificationAnswerWatcher!: CallableFunction
    private timeFrameWatcher!: CallableFunction
    private workoutTimeWatcher!: CallableFunction
    private controlCommandsWatcher!: CallableFunction
    private syncWatcher?: CallableFunction
    runtimeProcessWatcher!: CallableFunction
    isItPaused = true

    get timerCurrentTime(): number {

        if (workoutActiveModule.isItAnRft && !workoutActiveModule.isItPreparationTime) {
            return runtimeProcessorModule.currentTime
        }

        return ((workoutActiveModule.totalTime as number) -
            (workoutActiveModule.elapsedTime as number) -
            runtimeProcessorModule.elapsedTimeSeconds)
            * 1000 // to MS
    }

    get timerCoundUp(): boolean {
        return runtimeProcessorModule.timerType === TIMER_TYPE.UP
    }

    get isItScheduled() {
        return workoutActiveModule.triggeredBy === TRAINING_TRIGGERED_BY.SCHEDULE
    }

    get showHours() {
        return (workoutActiveModule.totalTime as number / 60 / 60) >= 1
    }

    get totalTime() {
        return workoutActiveModule.totalTime ? workoutActiveModule.totalTime : 0
    }

    get workoutStarted() {
        // return workoutActiveModule.startedAt > 0
        return true
    }

    mounted() {

        this.notificationAnswerWatcher = notificationModule.$watch(
            thisModule => thisModule.answer,
            (answer: UserAnswer) => {
              this.userAnswerHandler(answer)
        })

        this.runtimeProcessWatcher = runtimeProcessorModule.$watch(timerModule => timerModule.status, status => {
            
            const { paused, stopped, finished } = status
            
            if (stopped || (finished && workoutActiveModule.isItTheLastTimeFrame)) {
                if (workoutActiveModule.isItTheLastTimeFrame) {
                    if (appModule.eCheckInOn) {
                        this.$router.push({ name: PAGES.CELEBRATION, params: { totalTime: this.totalTime.toString() }})
                    } else {
                        this.$router.push({ name: PAGES.HOME })
                    }
                } else {
                    if (appModule.eCheckInOn) {
                        eGymTrainingsModule.resetToDefault()
                    }
                    this.$router.back()
                }
            } else if (finished) {
                workoutActiveModule.loadTheNextTimeFrame()
                workoutActiveModule.timeFrameStart()
            } else {
                this.isItPaused = paused
            }
        })

        this.timeFrameWatcher = workoutActiveModule.$watch(m => m.timeFrameCoordinates, () => {
            eGymTrainingsModule.traceTimeFrame(workoutActiveModule.activeTimeFrame)
            workoutActiveManager.timeFrameSwitchingProcessor(workoutActiveModule.activeTimeFrame)
        })

        this.workoutTimeWatcher = runtimeProcessorModule.$watch(timerModule => timerModule.currentTime,
            (currentTime, prevTimeMarker) => {
                workoutActiveManager.syncOverTime({ currentTime, prevTimeMarker })
            })

        this.controlCommandsWatcher = ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback,
            lastMessage => {
                if (lastMessage != undefined) {
                    workoutActiveManager.controlCommandsProcessor(lastMessage)
                }
            })

        this.syncWatcher = ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {

            if (lastMessage != undefined) {

                if ([ CTRL_COMMAND_FEEDBACK.WORKOUT_GOT_BACKWARD, CTRL_COMMAND_FEEDBACK.WORKOUT_GOT_FORWARD,
                    CTRL_COMMAND_FEEDBACK.WORKOUT_PAUSED, CTRL_COMMAND_FEEDBACK.WORKOUT_RESUMED, 
                    CTRL_COMMAND_FEEDBACK.WORKOUT_STARTED ].indexOf(lastMessage.event) >= 0) {

                        workoutActiveManager.sync(lastMessage)
                    }
            }
        })

        workoutActiveManager.start();
    }

    forward() {
        eGymTrainingsModule.clearActiveTimeFrame()
        if (workoutActiveModule.isItTheLastPart) {

            if (!workoutActiveModule.isItPreparationTime) {

                if (workoutActiveModule.isItACircuit && workoutActiveModule.isItTheLastRound) {
                    this.stop()
                } else if (!workoutActiveModule.isItTheLastTimeFrame) {
                    workoutActiveManager.forward()
                } else {
                    this.stop()
                }
            } else if (!workoutActiveModule.isItTheLastTimeFrame) {
                workoutActiveManager.forward()
            }
        } else {
            workoutActiveManager.forward()
        }
    }

    backward() {
        eGymTrainingsModule.clearActiveTimeFrame()
        workoutActiveManager.backward()
    }

    pause() {
        workoutActiveManager.pause()
    }

    resume() {
        workoutActiveManager.resume()
    }

    stop() {
        if (this.isItScheduled && !workoutActiveModule.isItAnRft) {
            notificationModule.showNotification({ command: NotificationCommand.CANNOT_STOP_TRAINING, type: NotificationType.ALERT })
        } else {
            notificationModule.showNotification({ command: NotificationCommand.ARE_YOU_SURE, type: NotificationType.CONFIRM })
        }
    }

    userAnswerHandler(answer: UserAnswer) {
        if (answer === UserAnswer.POSITIVE) {
            if (!this.isItScheduled || workoutActiveModule.workout?.type == WorkoutTypes.RFT) {
                if (!this.workoutStarted) {
                    workoutActiveManager.stop()
                    this.$router.push({ name: PAGES.HOME })
                } else {
                    workoutActiveManager.stop()
                }
            }
        }
    }

    beforeDestroy() {
        [this.syncWatcher, this.notificationAnswerWatcher, this.runtimeProcessWatcher,
            this.timeFrameWatcher, this.workoutTimeWatcher, this.controlCommandsWatcher].forEach(
                unsubscibe => unsubscibe != undefined ? unsubscibe() : 0)
        
        workoutActiveManager.destroy()
    }
}