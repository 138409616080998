
export enum NotificationType {
  ALERT = 'alert',
  CONFIRM = 'confirm'
}

export enum UserAnswer {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NONE = ''
}

export enum NotificationCommand {
  CANNOT_STOP_TRAINING = 'CANNOT_STOP_TRAINING',
  SCHEDULED_TRAINING_WILL_START_SOON = 'SCHEDULED_TRAINING_WILL_START_SOON',
  ARE_YOU_SURE = 'ARE_YOU_SURE',
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
  ARE_YOU_SURE_TO_START_RFT = 'ARE_YOU_SURE_TO_START_RFT',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  CLOSE = 'CLOSE',
  NO_PLAYER_FEEDBACK = 'NO_PLAYER_FEEDBACK',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  EGYM_LOGOUT = 'EGYM_LOGOUT'
}
export enum NotificationTitle {
  CANNOT_STOP_TRAINING = 'CANNOT_STOP_TRAINING',
  SCHEDULED_TRAINING_WILL_START_SOON = 'SCHEDULED_TRAINING_WILL_START_SOON',
  ARE_YOU_SURE = 'ARE_YOU_SURE',
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
  ARE_YOU_SURE_TO_START_RFT = 'ARE_YOU_SURE_TO_START_RFT',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  CLOSE = 'CLOSE',
  NO_PLAYER_FEEDBACK = 'NO_PLAYER_FEEDBACK',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  EGYM_LOGOUT = 'EGYM_LOGOUT'
}