import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { virtualClassActiveModule } from '@/store/modules/virtual-class-active.module'
import { ACTIVE_TRAINING_STATE, TRAINING_TRIGGERED_BY } from '@/types/active-training'
import timeformat from '@/filters/timeformat'
import runtimeProcessorModule from '@/store/modules/runtime-processor.module'
import { notificationModule } from '@/store/modules/notification.module'
import { UserAnswer, NotificationCommand, NotificationType } from '@/types/notification'
import { ctrlCmdModule } from '@/store/modules/control-commands.module'
import { CTRL_COMMAND_FEEDBACK } from '@/types/socket'
import { PAGES } from '@/types/router'

@Component({
  filters: { timeformat }
})
export default class VirtualClassActivePageComponent extends Vue {
  
  private notificationAnswerWatcher!: CallableFunction

  private ctrlCmdWatcher!: CallableFunction

  private runtimeWatcher!: CallableFunction

  get time() {
    return runtimeProcessorModule.currentTime
  }

  get isPaused() {
    return virtualClassActiveModule.status === ACTIVE_TRAINING_STATE.PAUSED
  }

  get isItScheduled() {
    return virtualClassActiveModule.triggeredBy === TRAINING_TRIGGERED_BY.SCHEDULE
  }

  created() {
    this.notificationAnswerWatcher = notificationModule.$watch(
      thisModule => thisModule.answer,
      (answer: UserAnswer) => {
        this.userAnswerHandler(answer)
      })
  }

  mounted() {

    virtualClassActiveModule.start()

    this.runtimeWatcher = runtimeProcessorModule.$watch(timerModule => timerModule.status, status => {
      
      if (status.finished) {

        if (this.$route.name == PAGES.ACTIVE_VIRTUAL_CLASS) {
          this.$router.back()
        }
      }
    })

    this.ctrlCmdWatcher = ctrlCmdModule.$watch(module => module.virtualTrainingCmdLastFeedback, feedback => {

      if ( feedback && feedback.event === CTRL_COMMAND_FEEDBACK.LESSON_STOPPED && 
        virtualClassActiveModule.virtualClass?.id == feedback.value.id ) {

          virtualClassActiveModule.resetToDefault()
          ctrlCmdModule.cmdDisposal(feedback.event)
        
          if (this.$route.name == PAGES.ACTIVE_VIRTUAL_CLASS) {
            this.$router.back()
          }
      } else if (feedback && feedback.event === CTRL_COMMAND_FEEDBACK.LESSON_SYNC) {
        if (feedback.value.time) {
          virtualClassActiveModule.sync(feedback.value.time)
        }
        ctrlCmdModule.cmdDisposal(feedback.event)
      }
    })
  }

  pause() {
    virtualClassActiveModule.pause()
  }

  resume() {
    virtualClassActiveModule.resume()
  }

  stop() {
    if (this.isItScheduled) {
      notificationModule.showNotification({ command: NotificationCommand.CANNOT_STOP_TRAINING, type: NotificationType.ALERT })
    } else {
      notificationModule.showNotification({ command: NotificationCommand.ARE_YOU_SURE, type: NotificationType.CONFIRM })
    }
  }

  userAnswerHandler(answer: UserAnswer) {
    
    if (answer === UserAnswer.POSITIVE && !this.isItScheduled) {
      
      virtualClassActiveModule.stop().catch(() => {
        virtualClassActiveModule.resetToDefault()
      }).finally(() => {
        if (this.$route.name == PAGES.ACTIVE_VIRTUAL_CLASS) {
          this.$router.back()
        }
      })
    }
  }

  beforeDestroy() {
    if (this.notificationAnswerWatcher) {
      this.notificationAnswerWatcher()
    }
    if (this.runtimeWatcher) {
      this.runtimeWatcher()
    }
    if (this.ctrlCmdWatcher) {
      this.ctrlCmdWatcher()
    }
    virtualClassActiveModule.resetToDefault()
  }
}