import Vue from 'vue'
import Component from 'vue-class-component'
import runtimeProcessorModule from '@/store/modules/runtime-processor.module';
import { TIMER_TYPE } from '@/types/timer';
import { notificationModule } from '@/store/modules/notification.module';
import { UserAnswer, NotificationCommand, NotificationType } from '@/types/notification';
import { TRAINING_TRIGGERED_BY } from '@/types/active-training';
import WorkoutActiveTimerComponent from '@/components/workout-active-timer/workout-active-timer.component.vue';
import timeformat from '@/filters/timeformat'
import { websoketConnectionModule } from '@/store/modules/websocket-connection.module';
import { BaseWorkout } from '@/types/workouts';
import { CTRL_COMMAND } from '@/types/socket';
import { ctrlCmdModule } from '@/store/modules/control-commands.module';
import workoutActiveModule from '@/store/modules/mss-workout-active.module';
import {appModule} from "@/store/modules/app.module";
import {PAGES} from "@/types/router";
import {MADE_BY_EGYM_TRAINER} from "@/types/e-gym";
import {eGymTrainingsModule} from "@/store/modules/egym-trainings.module";

@Component({
    components: {
        'timer': WorkoutActiveTimerComponent
    },
    filters: { timeformat },
})
export default class MssWorkoutActivePageComponent extends Vue {

    private notificationAnswerWatcher!: CallableFunction
    private timeFrameWatcher!: CallableFunction
    private runtimeProcessWatcher!: CallableFunction
    
    isItPaused = true

    get timerDuration() {
        return 0
    }

    get timerCurrentTime() {

        let secondsLeft = (workoutActiveModule.totalTime as number) - (workoutActiveModule.currentTimeSec as number)
        secondsLeft *= 1000 // to MS

        return secondsLeft
    }

    get timerCoundUp(): boolean {
        return runtimeProcessorModule.timerType === TIMER_TYPE.UP
    }

    get isItScheduled() {
        return workoutActiveModule.triggeredBy === TRAINING_TRIGGERED_BY.SCHEDULE
    }

    get showHours() {
        return (workoutActiveModule.totalTime as number / 60 / 60) >= 1
    }

    mounted() {

        this.notificationAnswerWatcher = notificationModule.$watch(
            thisModule => thisModule.answer,
            (answer: UserAnswer) => this.userAnswerHandler(answer))

        this.runtimeProcessWatcher = runtimeProcessorModule.$watch(timerModule => timerModule.status, status => {

            const { paused, finished, stopped } = status

            if (finished && workoutActiveModule.isItTheLastTimeFrame) {
                if (appModule.eCheckInOn && workoutActiveModule.workout?.made_by == MADE_BY_EGYM_TRAINER) {
                    this.$router.push({ name: PAGES.CELEBRATION, params: { totalTime: (workoutActiveModule.totalTime as number).toString() }})
                } else {
                    this.$router.back()
                }
            } else if (stopped ) {
                this.$router.back()
            } else {
                this.isItPaused = paused
            }
        })

        workoutActiveModule.start().catch(() => {
            websoketConnectionModule.reconnect()
            this.$router.back()
        })
    }

    forward() {

        if (workoutActiveModule.isItTheLastPart) {

            if (!workoutActiveModule.isItPreparationTime) {

                if (workoutActiveModule.isItACircuit && workoutActiveModule.isItTheLastRound) {
                    this.stop()
                } else if (!workoutActiveModule.isItTheLastTimeFrameInSet) {
                    this.onForward()
                } else {
                    this.stop()
                }
            } else if (!workoutActiveModule.isItTheLastTimeFrameInSet) {
                this.onForward()
            }
        } else {
            this.onForward()
        }
    }

    backward() {

        const { paused } = runtimeProcessorModule.status
        if (paused) {
            runtimeProcessorModule.resume()
        }

        workoutActiveModule.backward()
    }

    pause() {
        workoutActiveModule.pause()
    }

    resume() {

        const { paused } = runtimeProcessorModule.status

        if (paused) {
            workoutActiveModule.resume()
        }
    }

    start() {
        null
    }

    stop() {
        if (this.isItScheduled) {
            notificationModule.showNotification({ command: NotificationCommand.CANNOT_STOP_TRAINING, type: NotificationType.ALERT })
        } else {
            notificationModule.showNotification({ command: NotificationCommand.ARE_YOU_SURE, type: NotificationType.CONFIRM })
        }
    }

    onForward() {
        const { paused } = runtimeProcessorModule.status
        if (paused) {
            runtimeProcessorModule.resume()
        }

        workoutActiveModule.forward()
    }

    userAnswerHandler(answer: UserAnswer) {
        if (answer === UserAnswer.POSITIVE && !this.isItScheduled) {
            workoutActiveModule.stop()

            const { id } = workoutActiveModule.workout as BaseWorkout
            ctrlCmdModule.triggerCtrlCmd({ trigger: CTRL_COMMAND.WORKOUT_STOP, value: { id } })
        }
    }

    beforeDestroy() {
        [
            this.notificationAnswerWatcher, 
            this.runtimeProcessWatcher,
            this.timeFrameWatcher
        ].forEach(unsubscibe => unsubscibe != undefined ? unsubscibe() : 0)
        workoutActiveModule.resetToDefault()
    }
}